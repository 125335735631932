import React from 'react';

interface IData {
  title: string;

  icon: string;
}

const data: IData[] = [
  {
    "title": "React",
    "icon": "devicon-react-original-wordmark colored"
  },
  {
    "title": "Java",
    "icon": "devicon-java-plain colored"
  },
  {
    "title": "Flutter",
    "icon": "devicon-flutter-plain colored"
  },
  {
    "title": "Html",
    "icon": "devicon-html5-plain colored"
  },
  {
    "title": "CSS",
    "icon": "devicon-css3-plain colored"
  },
  {
    "title": "Bootstrap",
    "icon": "devicon-bootstrap-plain-wordmark colored",
  },
  {
    "title": "Tailwindcss",
    "icon": "devicon-tailwindcss-original-wordmark colored",
  },
  {
    "title": "Javascript",
    "icon": "devicon-javascript-plain colored"
  },
  {
    "title": "Angular",
    "icon": "devicon-angularjs-plain colored"
  },
  {
    "title": "PostgreSQL",
    "icon": "devicon-postgresql-plain colored"
  },
  {
    "title": "MySQL",
    "icon": "devicon-mysql-plain colored"
  },
  {
    "title": "MongoDB",
    "icon": "devicon-mongodb-plain colored"
  },
  {
    "title": "Wordpress",
    "icon": "devicon-wordpress-plain colored"
  },
  {
    "title": "C",
    "icon": "devicon-c-plain colored"
  },
  {
    "title": "C++",
    "icon": "devicon-cplusplus-plain colored"
  },
  {
    "title": "Docker",
    "icon": "devicon-docker-plain colored"
  },
  {
    "title": "AWS",
    "icon": "devicon-amazonwebservices-original colored"
  },
  {
    "title": "Woo",
    "icon": "devicon-woocommerce-plain-wordmark colored"
  },
  {
    "title": "Arduino",
    "icon": "devicon-arduino-plain-wordmark colored"
  },
  {
    "title": "Python",
    "icon": "devicon-python-plain-wordmark colored"
  }
];

export function TechnoSection() {
  return (
    <div id="technos" className="container py-20 justify-between items-center">
      <h2 className="dark:text-white text-4xl">Technologies récentes</h2>
      <div className="grid grid-cols-4 gap-4 py-20">
        {data.map((d, i) => {
          return (
            <div>
              <i className={d.icon} style={{ fontSize: "50px" }} />
              <div>
                <div className="text-black text-sm mt-2">{d.title}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}
