import React from 'react';
import './App.css';
import { Banner } from './containers/Banner';
import { Footer } from './containers/Footer';
import { NavSection } from './containers/NavSection';
import { ProcessSection } from './containers/ProcessSection';
import { ServicesSection } from './containers/ServicesSection';
import { TechnoSection } from './containers/TechnoSection';

function App() {
  return (
    <div className="App">
      <NavSection />
      <div>
        <Banner />
        <ServicesSection />
        <TechnoSection />
        <ProcessSection />
        <Footer />
      </div>
    </div>
  );
}

export default App;
