import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import React from "react";
import { isMobile } from 'react-device-detect';

interface IData {
  active: boolean;
  title: string;
  content: string;
  text: string;
}

const data: IData[] = [
  {
    active: true,
    text: "1",
    title: "Compréhension du besoin",
    content: "Nous répondons à votre problématique de la meilleure façon possible par le développement d'une solution digitale sous la forme d'une plate-forme web, mobile ou d'une solution embarquée."
  },
  {
    active: true,
    text: "2",
    title: "Conception",
    content: "Nous définissons fonctionnellement et graphiquement vos besoins sous la forme d'une maquette graphique de l'application à l'apparence identique au futur produit développé."
  },
  {
    active: true,
    text: "3",
    title: "Développements",
    content: "Le développement démarre après la validation de la phase de conception. Les priorités sont définies avec nous par rapport à la liste des fonctions identifiés. Un lien permet de suivre l'avancée du développement à tout moment"
  },
  {
    active: true,
    text: "4",
    title: "Lancement",
    content: "Votre projet est prêt à être diffusé massivement à vos utilisateurs ? Nous restons à vos côtés pour le faire évoluer et faire perdurer son succès."
  }
];

export function ProcessSection() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div id="process">
      <div className="bg-gray-100 dark:bg-dark-200 py-40">
        <h2 className="text-4xl">Nous vous offrons</h2>
        <div className="container mt-10">
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel orientation={isMobile ? "vertical" : "horizontal"}>
              {data.map((step, index) => (
                <Step key={step.title}>
                  <StepLabel >
                    {step.title}
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.content}</Typography>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={() => {
                            if (index === data.length - 1) {
                              handleReset();
                            } else {
                              handleNext();
                            }
                          }}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === data.length - 1 ? 'Fini' : 'Suivant'}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Précedent
                        </Button>
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
      </div>
    </div >
  );
}
