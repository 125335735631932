import React from 'react';

interface IData {
  title: string;
  content: string;
  image: string;
}

const data: IData[] = [
  {
    "title": "Création de site internet",
    "content": "Plateforme de marque, contenu, servicielle ou CRM, nous mettons toute notre expertise à votre service pour vous aider à construire votre projet.",
    "image": "assets/icons/internet.png"
  },
  {
    "title": "Application web",
    "content": "Nous nous allions avec vous pour répondrons à votre problématique en construisant une application sur-mesure et qui vous ressemble.",
    "image": "assets/icons/web-design.png"
  },
  {
    "title": "Application mobile",
    "content": "Applications métiers ou grand public, nous mettons toute notre expertise à votre service pour vous aider à construire votre produit mobile en natif, hybride.",
    "image": "assets/icons/mobile-app.png"
  }
];

export function ServicesSection() {
  return (
    <div id="services">
      <div className="bg-gray-100 dark:bg-dark-200 py-40">
        <div className="container">
          <div className="gap-20 grid-cols-1 grid-rows-3 grid mb-20 md:grid-cols-2 lg:grid-cols-3 md:grid-rows-2 lg:grid-rows-1">
            {data.map((d, i) => {
              return (
                <div key={i} className="relative">
                  <div className="bg-white p-10 relative shadow-sm rounded-3xl dark:bg-dark-300"  >
                    <div className="-mt-24 flex-col flex items-center mb-5 relative">
                      <img
                        className="border-8 border-gray-100 rounded-full w-28 dark:border-dark-200"
                        src={d.image}
                        alt="icon div"
                      />
                      <p className="font-semibold mb-0 mt-2 text-black-dark dark:text-white">
                        {d.title}
                      </p>
                    </div>
                    <p className="mb-8 text-black line-clamp-6">
                      {d.content}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
