import * as React from 'react';

export interface INavSectionProps {
}

export function NavSection(props: INavSectionProps) {
  return (
    <div>
      <header className="fixed shadow w-full z-50 bg-white dark:bg-dark-100 transition-colors duration-150">
        <div className="container flex h-20 items-center justify-between">
          <div>
            <a href="/">
              <img
                src="assets/logo.png"
                alt="site logo"
                style={{ width: "200px" }} />
            </a>
          </div>

          <nav id="mainNavigation"
            className="bg-white dark:bg-black-dark dark:text-white fixed hidden left-0 min-h-screen top-0 w-2/3 md:block shadow-drawer md:relative md:shadow-none md:min-h-0 dark:md:bg-transparent md:w-auto">
            <ul className="flex-col flex md:flex-row">
              <li>
                <a className="duration-300 font-medium inline-block mt-5 px-10 transition md:mt-0 md:px-5 text-black hover:text-black-dark dark:hover:text-black-light"
                  href="#home">Accueil</a>
              </li>
              <li>
                <a className="duration-300 font-medium inline-block mt-5 px-10 text-black transition md:mt-0 md:px-5 hover:text-black-dark"
                  href="#services">Services</a>
              </li>
              <li>
                <a className="duration-300 font-medium inline-block mt-5 px-10 text-black transition md:mt-0 md:px-5 hover:text-black-dark"
                  href="#technos">Technologies</a>
              </li>
              <li>
                <a className="duration-300 font-medium inline-block mt-5 px-10 text-black transition md:mt-0 md:px-5 hover:text-black-dark"
                  href="#process">Processus</a>
              </li>
              <li>
                <a className="duration-300 font-medium inline-block mt-5 px-10 text-black transition md:mt-0 md:px-5 hover:text-black-dark"
                  href="#contact">Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}
