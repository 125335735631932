import React from 'react';

export function Banner() {
  return (
    <div id="home" style={{ marginBottom: "50px" }}>
      <div className="container pt-40 space-y-20">
        <h1 className="text-3xl text-center md:text-7xl dark:text-white font-bold">Nos <span className="text-blue-600">expertises</span></h1>
        <p className="max-w-2xl mx-auto text-center text-sm md:text-base">
          Nous concevons, développons et maintenons des applications web et mobiles ou embarqué pour répondre à vos
          problématiques.
        </p>
      </div>
    </div>
  );
}
